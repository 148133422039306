import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

const StepTwoLogin = ({ action, states, setters }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)


    const navigate = useNavigate()

    const onSubmit = async (e) => {
        e.preventDefault()
        if (states.passwordInput.length < 8 || states.passwordInput === '' || states.passwordInput === undefined || states.passwordInput === null || states.passwordInput === ' ') {
            setters.setErrorPassword(true)
            return
        } else {
            setters.setErrorPassword(false)
        }
        if (states.confirmPasswordInput.length < 8 || states.confirmPasswordInput === '' || states.confirmPasswordInput === undefined || states.confirmPasswordInput === null || states.confirmPasswordInput === ' ') {
            setters.setErrorConfirmPassword(true)
            return
        } else {
            setters.setErrorConfirmPassword(false)
        }
        if (states.confirmPasswordInput !== states.passwordInput) {
            setters.setErrorConfirmPassword(true)
            return
        } else {
            setters.setErrorConfirmPassword(false)
        }
        const haveUpperCaseInPassword = states.passwordInput.match(/[A-Z]/)
        const haveLowerCaseInPassword = states.passwordInput.match(/[a-z]/)
        const haveNumbersInPassword = states.passwordInput.match(/[0-9]/)
        const haveSpecialCharactersInPassword = states.passwordInput.match(/[^a-zA-Z0-9]/)
        if (!haveUpperCaseInPassword || !haveLowerCaseInPassword || !haveNumbersInPassword || !haveSpecialCharactersInPassword) {
            setters.setErrorConfirmPassword(true)
            return
        } else {
            setters.setErrorConfirmPassword(false)
        }


        await action()
    }

    const returnToStepOne = async () => {
        setters.setStep1(true)
        setters.setStep2(false)
        setters.setIsComplete(false)
        setters.setErrorCpf(false)
        setters.setErrorPassword(false)
        setters.setErrorConfirmPassword(false)
        setters.setForgotPassword(false)
        setters.setCpfInput('')
        setters.setPasswordInput('')
        setters.setAssociate_id('')
        setters.setName('')
        navigate('/')
    }

    return (
        <div className="mt-8">

            <div className="mt-6">
                <form action="#" method="POST" className="space-y-6" onSubmit={onSubmit}>
                    <div>
                        <label
                            htmlFor="cpf"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Digite sua senha
                        </label>
                        <div className={
                            states.errorPassword === true ? (
                                "p-3 block mt-2 w-full center rounded-md border py-1.5 border border-red-500 shadow-sm ring-1 ring-inset ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                            ) : (
                                "p-3 block w-full mt-2 rounded-md border py-1.5 border border-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                            )
                        } >
                            <div className="w-full flex ">
                                <input type={showPassword === true ? "text" : "password"}
                                    name="password" id="password"
                                    className='w-full'
                                    value={states.passwordInput}
                                    onChange={(e) => setters.setPasswordInput(e.target.value)}
                                />
                                <div className=" inset-y-0 right-0 flex items-center">
                                    <button type="button" onClick={() => setShowPassword(!showPassword)} className=''>
                                        {
                                            showPassword === true ? (
                                                <EyeIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                            ) : (
                                                <EyeSlashIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            states.errorPassword === true &&
                            <p className="text-red-500 text-sm">Senha inválida</p>
                        }

                    </div>
                    <div>
                        <label
                            htmlFor="cpf"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Confirme sua senha
                        </label>



                        <div className={
                            states.errorConfirmPassword === true ? (
                                "p-3 block w-full rounded-md border py-1.5 border border-red-500 shadow-sm ring-1 ring-inset ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                            ) : (
                                "p-3 block w-full rounded-md border py-1.5 border border-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                            )
                        }>
                            <div className="w-full flex ">
                                <input 
                                type={showConfirmPassword === true ? "text" : "password"}
                                 name="" id=""
                                className='w-full'
                                    value={states.confirmPasswordInput}
                                    onChange={(e) => setters.setConfirmPasswordInput(e.target.value)}

                                />
                                <div className=" inset-y-0 right-0 flex items-center">
                                    <button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className=''>
                                        {
                                            showConfirmPassword === true ? (
                                                <EyeIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                            ) : (
                                                <EyeSlashIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            states.errorConfirmPassword === true &&
                                states.confirmPasswordInput === states.passwordInput ? (
                                <p className="text-red-500 text-sm">Senhas inválida. A senha deve ter 8 caracteres, pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial</p>
                            ) : states.errorConfirmPassword === true && states.confirmPasswordInput !== states.passwordInput ? (
                                <p className="text-red-500 text-sm">Senhas não conferem</p>
                            ) : (
                                <></>
                            )
                        }
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-blue-600">
                            {
                                states.isLoading === true ? (
                                    <p>Carregando...</p>
                                ) : (
                                    <p>Resetar senha</p>
                                )
                            }
                        </button>
                        <button
                            type="button"
                            onClick={returnToStepOne}
                            className="mt-5 flex w-full justify-center rounded-md bg-red-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-red-600">
                            {
                                states.isLoading === true ? (
                                    <p>Carregando...</p>
                                ) : (
                                    <p>Retornar</p>
                                )
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StepTwoLogin