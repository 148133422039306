import Navbar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import { AuthContextApi } from "../../Context/api";
import { AuthContext } from "../../Context";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClipboardDocumentListIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";



const AdminIndex = () => {

    const { checkLogin } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [])


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <Navbar />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                <h1 className="text-[30px] text-center font-bold">Painel Jurídico</h1>
                                <p className="text-[21px] mt-1 text-center">Aqui você terá acesso as áreas jurídicas do SINPREV </p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="flex items-center justify-center">
                                    <Link to="/juridico/processos" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <ClipboardDocumentListIcon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold text-center">Processos</h2>
                                        <p className="text-center">Clique nesse cartão para acessar sua assinatura</p>
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/juridico/informativo" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <InformationCircleIcon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold text-center">Informativo</h2>
                                        <p className="text-center">Clique nesse cartão para acessar seus cartões de crédito</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="pt-8">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdminIndex;
