import SpinnerOverlay from "../../Components/SpinnerOverlay"
import NavBar from "../../Components/NavBar"
import { AuthContext } from "../../Context"
import { AuthContextApi } from "../../Context/api"
import { useEffect, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie'
import moment from "moment"
import { PencilIcon } from "@heroicons/react/24/outline"
import {formatCpf} from '../../utils/index'
import Footer from "../../Components/Footer"
import axios from "axios"
import {toast} from 'react-toastify'


const Profile = () => {

    const { checkLogin, getProfile, updateProfile } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()
    const [cumprimento, setCumprimento] = useState('')
    const [now, setNow] = useState('')
    const [isEditing, setIsEditing] = useState(false)


    const isSigned = async () => {
        const response = await checkLogin()
        if (response === true) {
            setters.setSigned(true)
        } else {
            setters.setSigned(false)
            navigate('/')
            Cookies.remove('token')
        }
    }

    const getProfileData = async () => {
        await getProfile()
    }



    useEffect(() => {
        setNow(new Date())
        if (Object.keys(states.Profile).length === 0) {
            getProfileData()
        }
        isSigned()
        const dataHora = new Date()
        const hora = dataHora.getHours()
        if (hora >= 1 && hora <= 11) {
            setCumprimento('Bom dia')
        }
        else if (hora >= 12 && hora <= 17) {
            setCumprimento('Boa tarde')
        }
        else if (hora >= 18 && hora <= 23) {
            setCumprimento('Boa noite')
        }
        else {
            setCumprimento('Olá')
        }
        return () => {
            setters.setProfile({})
        }


    }, []);

    const changeCep = async (cep) => {
        if (cep.length === 8) {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            setters.setProfile({ ...states.Profile, address: response.data.logradouro, neighborhood: response.data.bairro, city: response.data.localidade, state: response.data.uf })
        }
    }

    const formatCep = (cep) => {
        return cep?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const updateProfileData = async () => {
        setters.setProfile({ ...states.Profile, dateFiliate: moment(states.Profile.dateFiliate).format('YYYY-MM-DD'), dateRg: moment(states.Profile.dateRg).format('YYYY-MM-DD'), birthDate: moment(states.Profile.birthDate).format('YYYY-MM-DD') })
       let error = false
        Object.keys(states.Profile).map((item) => {
            if (states.Profile[item] === '') {
                error = true
            }
        }
        )
        if (error) {
            toast.error('Preencha todos os campos')
            return
        }
        await updateProfile()
        setIsEditing(false)
    }




    function formatarNumero(numero) {
        const numStr = numero?.toString();
        return `${numStr?.slice(0, 3)}.${numStr?.slice(3, 8)}.${numStr?.slice(8)}`;
      }

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <div className="flex flex-col flex-1 items-center justify-center">
                    <SpinnerOverlay />

                    <div className='flex flex-1 flex-col items-center md:pl-64 mb-4 mt-2 w-full'>
                        <div className="p-4 border w-[95%]">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-[30px] text-base font-semibold leading-6 text-gray-900">Perfil</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    {cumprimento}, {states.Profile.name}
                                </p>
                                <p className="mt-2 text-lg text-gray-700">
                                    Aqui você pode visualizar e editar suas informações pessoais.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-1 flex-col items-center md:pl-64 mb-4 mt-2 w-full'>
                        <div className="p-4 border rouned-[10px] w-[95%]">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">Informações Pessoais</h1>
                                <div className="flex justify-end">
                                    <button onClick={() => setIsEditing(!isEditing)} className="text-sm text-gray-700 hover:text-gray-900 bg-blue-500 p-2 rounded-[15px]">
                                        <PencilIcon className="h-6 w-6 text-gray-100 cursor-pointer" />
                                    </button>
                                </div>
                                <div className="mt-2 text-sm text-gray-700">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="flex flex-col">
                                            <p>Nome</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.name} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, name: e.target.value })} />
                                                ) : null
                                            }
                                            {
                                                !isEditing &&
                                                <p>{states.Profile.name}</p>
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Matrícula do SINPREV</p>
                                            <p>{formatarNumero(states.Profile.registration)}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p>CPF</p>
                                            <p>{formatCpf(states.Profile.cpf)}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Email</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.email} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, email: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.email}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Data de filiação</p>
                                            <p>{moment(states.Profile.dateFiliate).format('DD/MM/YYYY')}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Sexo</p>
                                            {
                                                isEditing ? (
                                                    <select name="" id="" value={states.Profile.sexo} className="border border-gray-300 p-2 rounded-md"
                                                        onChange={(e) => setters.setProfile({ ...states.Profile, sexo: e.target.value })}
                                                    >
                                                        <option value="M">Masculino</option>
                                                        <option value="F">Feminino</option>
                                                    </select>
                                                ) : (
                                                    <p>{states.Profile.sexo}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Estado Civil</p>
                                            {
                                                isEditing ? (
                                                    <select name="" id="" value={states.Profile.civilState} className="border border-gray-300 p-2 rounded-md"
                                                        onChange={(e) => setters.setProfile({ ...states.Profile, civilState: e.target.value })}
                                                    >
                                                        <option value="Solteiro(a)">Solteiro(a)</option>
                                                        <option value="Casado(a)">Casado(a)</option>
                                                        <option value="Divorciado(a)">Divorciado(a)</option>
                                                        <option value="Viúvo(a)">Viúvo(a)</option>
                                                    </select>
                                                ) : (
                                                    <p>{states.Profile.civilState}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Escolaridade</p>
                                            {
                                                isEditing ? (
                                                    <select name="" id="" value={states.Profile.escolarchip} className="border border-gray-300 p-2 rounded-md"
                                                        onChange={(e) => setters.setProfile({ ...states.Profile, escolarchip: e.target.value })}
                                                    >
                                                        <option value="fundamental">Fundamental</option>
                                                        <option value="medio">Médio</option>
                                                        <option value="superior">Superior</option>
                                                        <option value="posgraduacao">Pós Graduação</option>
                                                    </select>
                                                        
                                                ) : (
                                                    <p>{states.Profile.escolarchip}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Naturalidade</p>
                                            {
                                                isEditing ? (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        <input type="text" value={states.Profile.hometown} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, hometown: e.target.value })} />
                                                        <input type="text" value={states.Profile.homeState} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, homeState: e.target.value })} />
                                                    </div>
                                                ) : (
                                                    <p>{states.Profile.hometown} - {states.Profile.homeState}</p>
                                                )
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>RG</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.rg} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, rg: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.rg}</p>)

                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Orgão Emissor</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.emissorEntityRg} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, emissorEntityRg: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.emissorEntityRg}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Data de Emissão</p>
                                            {
                                                isEditing ? (
                                                    <input type="date" value={moment(states.Profile.dateRg).format('YYYY-MM-DD')} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, dateRg: e.target.value })} />
                                                ) : (
                                                    <p>{moment(states.Profile.dateRg).format('DD/MM/YYYY')}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Nome do Pai</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.fathername} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, fathername: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.fathername}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Nome da Mãe</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.mothername} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, mothername: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.mothername}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Fundo</p>
                                            <p>{states.Profile.fundo}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">Endereço</h1>
                                <div className="mt-2 text-sm text-gray-700">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="flex flex-col">
                                            <p>CEP</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.cep} className="border border-gray-300 p-2 rounded-md" onChange={(e) => changeCep(e.target.value)} />
                                                ) : (
                                                    <p>{formatCep(states.Profile.cep)}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Endereço</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.address} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, address: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.address}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Complemento</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.complement} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, complement: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.complement}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Número</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.houseNumber} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, houseNumber: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.houseNumber}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Bairro</p>
                                            {
                                                isEditing ? (
                                                    <input type="text" value={states.Profile.neighborhood} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, neighborhood: e.target.value })} />
                                                ) : (
                                                    <p>{states.Profile.neighborhood}</p>)
                                            }
                                        </div>
                                        <div className="flex flex-col">
                                            <p>Cidade</p>
                                            {
                                                isEditing ? (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                        <input type="text" value={states.Profile.city} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, city: e.target.value })} />
                                                        <input type="text" value={states.Profile.state} className="border border-gray-300 p-2 rounded-md" onChange={(e) => setters.setProfile({ ...states.Profile, state: e.target.value })} />
                                                    </div>
                                                ) : (
                                                    <p>{states.Profile.city} - {states.Profile.state}</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    isEditing &&
                                    <div className="mt-4">
                                        <button className="bg-blue-500 text-gray-100 p-2 rounded-md" onClick={updateProfileData}
                                        >Salvar</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>  
        </>
    )
}


export default Profile