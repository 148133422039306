
import { functions } from '../../utils/index'

const StepOneLogin = ({ action, stap, name, complete,  states, setters }) => {


    const onSubmit = async (e) => {
        e.preventDefault()
        if (states.cpfInput.length < 11 || states.cpfInput === '' || states.cpfInput === undefined || states.cpfInput === null || states.cpfInput === ' ') {
            setters.setErrorCpf(true)
            return
        } else {
            setters.setErrorCpf(false)
        }
        await action()
    }
    return (
        <div className="mt-8">
            <div>
                <div className="flex justify-center items-center">
                    {
                        stap === false &&
                        <p className="px-2 bg-white text-gray-500">Olá, {name}</p>
                    }
                </div>
                <div className="relative mt-6">
                    <div className="absolute inset-0 flex items-center " aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm mt-8">
                        <span className="bg-white px-2 text-gray-500">{
                            stap === true ? (
                                "Digite seu CPF para continuar"
                            ) : (
                                `${complete === true ? (
                                    "Digite sua senha"
                                ) : (
                                    "Cadastre sua senha"
                                )
                                }`
                            )

                        }</span>
                    </div>
                </div>
            </div>

            <div className="mt-6">
                <form action="#" method="POST" className="space-y-6" onSubmit={onSubmit}>
                    <div>
                        <label
                            htmlFor="cpf"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            CPF
                        </label>
                        <div className="mt-2">
                            <input
                                id="cpf"
                                name="cpf"
                                type="cpf"
                                autoComplete="cpf"
                                onChange={e => {
                                    const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                    if (onlyNumbers.length <= 11) {
                                        setters.setCpfInput(onlyNumbers)
                                    }
                                }}
                                disabled={stap === false ? true : false}
                                onBlur={() => states.cpfInput.length < 11 ? setters.setErrorCpf(true) : setters.setErrorCpf(false)}
                                value={functions.formatCpf(states?.cpfInput)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSubmit(e)
                                    }
                                }}
                                // className="p-3 block w-full rounded-md border py-1.5 border border-grey-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                className={
                                    states.errorCpf === true ? (
                                        "p-3 block w-full rounded-md border py-1.5 border border-red-500 shadow-sm ring-1 ring-inset ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                    ) : states.step1 === true /*AQUI TEM QUE SER TRUE*/ ? (
                                        "p-3 block w-full rounded-md border py-1.5 border border-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    ) : (
                                        "p-3 block w-full rounded-md border py-1.5 border bg-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                    )
                                }
                            />
                            {
                                states.errorCpf === true ? (
                                    <p className="text-red-500 text-xs mt-1">CPF inválido</p>
                                ) : states.stap1 === true ? (
                                    <p className="text-gray-500 text-xs mt-1">Digite seu CPF</p>
                                ) : (<></>)
                            }
                        </div>

                    </div>
                    <div>
                        {
                            stap === true &&
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 hover:bg-blue-600">
                                {
                                    states.isLoading === true ? (
                                        <p>Carregando...</p>
                                    ) : (
                                        <p>Realizar acesso</p>
                                    )
                                }
                            </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StepOneLogin