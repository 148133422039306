
import NavBar from '../../../Components/NavBar'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Faq from '../../../Components/FaqContabilidade'
import Cookies from 'js-cookie'

const Faturas = () => {

    const { checkLogin, getDocsOfficiais } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }



    useEffect(() => {
        isSigned()
        if (states.dataDocs.length === 0) {
            getDocsOfficiais()
        }
        return () => {
            setters.setDataDocs([])
        }
    }, [])





    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-col sm:flex-row w-full items-between justify-between mt-8">

                                <div className="flex flex-col w-full items-between justify-between">
                                    <h1 className="text-[30px] font-semibold leading-6 text-gray-900">Relatórios Contábeis</h1>
                                </div>

                            </div>
                        </div>
                        <div className="px-4 sm:px-6 lg:px-8 mt-16">
                            <div className="px-4 sm:px-6 lg:px-8 mt-4 border p-5 rounded-[10px]  shadow">
                                {
                                    states.dataDocs.length > 0 ? (
                                        <>
                                            {
                                                states.dataDocs.map((doc, index) => {
                                                    return (
                                                        <>
                                                            <Faq key={index} faqs={doc} />
                                                            <hr className="my-8 p-2 border-t-4 border-blue-400"/>
                                                        </>
                                                    )
                                                }
                                                )
                                            }

                                        </>
                                    ) : (
                                        <div className="flex flex-col items-center justify-center">
                                            <p className="text-lg font-semibold text-gray-900">Nenhum documento encontrado</p>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}



export default Faturas;