import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from "react";
import RouterWrapper from './auth';




import NotFound from '../Views/NotFound';
import Login from '../Views/Login';
import DashBoard from '../Views/DashBoard';
import Profile from '../Views/Profile';


// import Comunicacao from '../Views/Comunicacao';
import PerguntasFrequentes from '../Views/Comunicacao/PerguntasFrequentes';
import Noticias from '../Views/Comunicacao/Noticias';
import Noticia from '../Views/Comunicacao/Noticia';

import Financeiro from '../Views/Financeiro';
// import DocumentosOficiais from '../Views/DocumentosOficiais';
import Contabilidade from '../Views/DocumentosOficiais/Contabilidade';
import AGO from '../Views/Ago';
import AGO2024 from '../Views/Ago/2024';

import Cartoes from '../Views/Financeiro/Cartoes';
import Faturas from '../Views/Financeiro/Faturas';
import Assinatura from '../Views/Financeiro/Assinatura';

import Juridico from '../Views/Juridico';
import JuridicoProcessos from '../Views/Juridico/Processos';
import JuridicoInformativo from '../Views/Juridico/Informativo';

// const RouterComnunicacao = () => {
//     return (
//         <Routes>
//             <Route path="/" element={<RouterWrapper component={Comunicacao} isPrivate={true} />} />
//             <Route path="/faq" element={<RouterWrapper component={PerguntasFrequentes} isPrivate={true} />} />
//             <Route path="/noticias" element={<RouterWrapper component={Noticias} isPrivate={true} />} />
//             <Route path="/noticia/:id" element={<RouterWrapper component={Noticia} isPrivate={true} />} />
//             <Route path="/agoabril2024" element={<RouterWrapper component={AGO} isPrivate={true} />} />
//         </Routes>
//     )

// }

const RouterAgo = () => {
    return (
        <Routes>
            <Route path="/" element={<RouterWrapper component={AGO} isPrivate={true} />} />
            <Route path="/2024" element={<RouterWrapper component={AGO2024} isPrivate={true} />} />
        </Routes>
    )

}

const RouterJuridico = () => {
    return (
        <Routes>
            <Route path="/" element={<RouterWrapper component={Juridico} isPrivate={true} />} />
            <Route path="/processos" element={<RouterWrapper component={JuridicoProcessos} isPrivate={true} />} />
            <Route path="/informativo" element={<RouterWrapper component={JuridicoInformativo} isPrivate={true} />} />
        </Routes>
    )

}

const RouterFinanceiro = () => {
    return (
        <Routes>
            <Route path="/" element={<RouterWrapper component={Financeiro} isPrivate={true} />} />
            <Route path="/cartoes" element={<RouterWrapper component={Cartoes} isPrivate={true} />} />
            <Route path="/faturas" element={<RouterWrapper component={Faturas} isPrivate={true} />} />
            <Route path="/assinatura" element={<RouterWrapper component={Assinatura} isPrivate={true} />} />
        </Routes>
    )

}

// const RouterDocumentosOficiais = () => {
//     return (
//         <Routes>
//             <Route path="/" element={<RouterWrapper component={DocumentosOficiais} isPrivate={true} />} />
//             <Route path="/contabilidade" element={<RouterWrapper component={Contabilidade} isPrivate={true} />} />
//             <Route path="/agoabril2024" element={<RouterWrapper component={AGO} isPrivate={true} />} />
//         </Routes>
//     )

// }



const Router = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])


    return (
        <Routes>
            {/* <Route path="/comunicacao/*" element={<RouterComnunicacao />} /> */}
            <Route path="/faq" element={<RouterWrapper component={PerguntasFrequentes} isPrivate={true} />} />
            <Route path="/noticias" element={<RouterWrapper component={Noticias} isPrivate={true} />} />
            <Route path="/noticia/:id" element={<RouterWrapper component={Noticia} isPrivate={true} />} />
            <Route path="/agoage/*" element={<RouterWrapper component={RouterAgo} isPrivate={true} />} />
            <Route path="/financeiro/*" element={<RouterFinanceiro />} />
            <Route path="/relatoriosContabeis" element={<RouterWrapper component={Contabilidade} isPrivate={true} />} />
            <Route path="/comunicacaoago" element={<RouterWrapper component={AGO} isPrivate={true} />} />
            <Route path="/juridico/*" element={<RouterWrapper component={RouterJuridico} isPrivate={true} />} />
            <Route path="/dashboard" element={<RouterWrapper component={DashBoard} isPrivate={true} />} />
            <Route path="/profile" element={<RouterWrapper component={Profile} isPrivate={true} />} />
            <Route path="/:token?" element={<RouterWrapper component={Login} isPrivate={false} />} />
            <Route path="*" element={<RouterWrapper component={NotFound} isPrivate={false} />} />
        </Routes>
    )
}

export default Router