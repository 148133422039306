
import NavBar from '../../../Components/NavBar'
import NavBarMenu from '../../../Components/NavMenu/comunicacao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import News from '../../../Components/News'

const Noticias = () => {

    const { checkLogin, getNews } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    const getNewsData = async () => {
        await getNews()

    }

    useEffect(() => {
        isSigned()
        if (states.dataNews.length === 0) {
            getNewsData()
        }
        return () => {
            setters.setDataNews([])
            setters.setLimitSearchNews(false)
            setters.setOffsetNews(0)
        }
    }, [])

    

    const loadMoreNews = async () => {
        if(states.limitSearchNews){
            return;
        }else{
            setters.setOffsetNews(states.offsetNews + states.limitNews)
            getNewsData()
        }
    }

    useEffect(() => {
        if(states.limitSearchNews){
            return;
        }
        if(states.offsetNews > 0){
            getNewsData()
        }
    }, [states.offsetNews])



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            {/* <NavBarMenu /> */}
                            <h1 className="text-[30px] text-base font-semibold leading-6 text-gray-900">Notícias</h1>
                            <p className="mt-2 text-lg text-gray-700">
                                Últimas notícias sobre o SINPREV
                            </p>
                        </div>
                    </div>
                    {
                        states.dataNews.length > 0 ? (
                            <div className="px-4 sm:px-6 lg:px-8 p-8 mt-5 ">
                                <div className="px-4 sm:px-6 lg:px-8 border rounded-[10px] shadow p-8">
                                    <News news={states.dataNews} states={states} setters={setters} more={loadMoreNews}/>
                                </div>
                            </div>
                        ) : (
                            /* Nao encontrado - menssage */
                            <div className="px-4 sm:px-6 lg:px-8 p-8 mt-5 ">
                                <div className="px-4 sm:px-6 lg:px-8 border rounded-[10px] shadow p-8">
                                    <p className="text-center text-xl font-semibold text-gray-900">Nenhuma notícia encontrada</p>
                                </div>
                            </div>
                        )
                    }
                </div>
            <Footer />
            </div>
        </>
    );
}



export default Noticias;