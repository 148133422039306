import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Context";
import { AuthContextApi } from "../Context/api";
import Sidebar from "../Components/SideBar";
import Cookie from 'js-cookie'

const RouterWrapper = ({ component: Component, isPrivate }) => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin } = useContext(AuthContextApi)
    const navigate = useNavigate()
    const { token } = useParams()



    const isSigned = async () => {
        const tokenCookie = Cookie.get('token')
        if (tokenCookie === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookie.remove('token')
                setters.setSigned(false)
                setters.setStep1(true)
                setters.setStep2(false)
                setters.setIsComplete(false)
                setters.setErrorCpf(false)
                setters.setErrorPassword(false)
                setters.setErrorConfirmPassword(false)
                setters.setCpfInput('')
                setters.setPasswordInput('')
                setters.setAssociate_id('')
                setters.setName('')
                setters.setForgotPassword(false)
                setters.setTokenForgotPassword('')
                if(token){
                    setters.setForgotPassword(true)
                    setters.setTokenForgotPassword(token)
                    navigate(`/${token}`)
                }else{
                    navigate('/')
                }
            }

        }
    }

    useEffect(() => {
        isSigned()
    }, [Cookie.get('token')])


    if (isPrivate) {
        if (states.signed) {
            return (
                <>
                    <Sidebar />
                    <Component />
                </>
            )
        } else {
            if (Cookie.get('token') === null) {
                return (
                    <Navigate to='/' />
                )
            } else {
                isSigned()
                return (
                    <>
                        <Sidebar />
                        <Component />
                    </>
                )
            }
        }
    }

    if (isPrivate && !states.signed) {
        return (
            <Navigate to='/' />
        )
    }

    if (isPrivate && states.signed) {
        return (
            <Navigate to='/dashboard' />
        )
    }

    if (!isPrivate) {
        return (
            <Component />
        )
    }
    return (
        <Component />
    )



}

export default RouterWrapper;