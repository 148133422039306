
import NavBar from '../../../Components/NavBar'
import NavBarMenu from '../../../Components/NavMenu/cartao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import Cookies from 'js-cookie'
import Modal from '../../../Components/Modal/MetodoAssinatura'
import moment from 'moment'
import { truncate } from '../../../utils'

const Assinatura = () => {

    const { checkLogin, getSignature, changeMethodSignature } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    const getCardsData = async () => {
        await getSignature()

    }
    //31971366684
    const openLinkWhatsApp = () => {
        window.open('https://api.whatsapp.com/send?phone=31971366684&text=Ol%C3%A1%2C%20gostaria%20de%20falar%20com%20um%20atendente', '_blank')
    }

    useEffect(() => {
        isSigned()
        if (Object.keys(states.Signatgure).length === 0) {
            getCardsData()
        }
        return () => {
            setters.setSignatgure({})
        }
    }, [])

    useEffect(() => {
        //console.log(states.Signatgure)
    }, [states.Signatgure])

    const formatToBRL = (value) => {
        const realValue = value / 100
        return realValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }


    const actionModal = async () => {
        setters.setModalSignature(false)
        await changeMethodSignature()
    }





    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarMenu />
                            <div className="flex flex-col sm:flex-row w-full items-between justify-between">

                                <div className="flex flex-col w-full items-between justify-between">
                                    <h1 className="text-[30px] font-semibold leading-6 text-gray-900">Assinatura</h1>
                                    <p className="mt-2 text-lg text-gray-700">
                                        Dados da sua assinatura registrada no SINPREV
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="px-4 sm:px-6 lg:px-8 mt-4 border p-5">

                                {
                                    Object.keys(states.Signatgure).length === 0 ? (
                                        <>
                                            <h2 className="text-base font-semibold leading-6 text-gray-900">Assinatura Cadastrada</h2>
                                            <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4">
                                                <p>Nenhuma assinatura cadastrada no momento, clique no botão para falar com um atendente</p>
                                            </div>
                                            <button onClick={() => openLinkWhatsApp()} className="flex items-center justify-center w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                <PlusCircleIcon className="h-5 w-5" />
                                                <span className="ml-2">Falar com atendente</span>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <h2 className="text-base font-semibold leading-6 text-gray-900">Registro da sua assinatura</h2>
                                            <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4 p-5">
                                                <div className="flex flex-col w-full items-between justify-between">
                                                    <p className="text-md text-gray-700">Status: {states.Signatgure.statusPaid}</p>
                                                    <p className="text-md text-gray-700">Dia de cobrança: {states.Signatgure.dayCharge}</p>
                                                    <p className="text-md text-gray-700">Valor: {formatToBRL(states.Signatgure.price)}</p>
                                                </div>
                                                <div className="flex flex-col w-full items-between justify-between">
                                                    <p className="text-md text-gray-700">Método de pagamento: {states.Signatgure.method
                                                        == 'credit_card' ? 'Cartão de Crédito' : 'bank_slip' ? 'Boleto' : states.Signatgure.isFuncef == 1 ? 'Funcef' : 'Não informado'}</p>
                                                    <p className="text-md text-gray-700">Data de criação: {moment(states.Signatgure.createdAt).format('DD/MM/YYYY')}</p>
                                                </div>
                                                <div className="flex flex-col w-full items-between justify-between">
                                                    <p className="text-md text-gray-700">Data de atualização: {moment(states.Signatgure.updatedAt).format('DD/MM/YYYY')}</p>
                                                    <p className="text-md text-gray-700">Frequência: {states.Signatgure.frequency > 1 ? `${states.Signatgure.frequency} meses` : `${states.Signatgure.frequency} mês`}</p>
                                                </div>
                                            </div>
                                            {
                                                states.Signatgure.idIuguSignature !== '0' &&
                                                <div className="flex flex-col w-full items-between justify-between w-full">
                                                    <button onClick={() => setters.setModalSignature(true)} className="flex items-center justify-center w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                        <PlusCircleIcon className="h-5 w-5" />
                                                        <span className="ml-2">Trocar Método de pagamento</span>
                                                    </button>
                                                    <button onClick={() => openLinkWhatsApp()} className="flex items-center justify-center w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                        <PlusCircleIcon className="h-5 w-5" />
                                                        <span className="ml-2">Deseja trocar o dia da sua assinatura? Clique aqui e entre em contato com o atendimento</span>
                                                    </button>
                                                </div>
                                            }
                                        </>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
                {/* <ModalCreditCard states={states} setters={setters} action={actionModal} /> */}
            </div>
            <Modal action={actionModal} states={states} setters={setters} title={'Deletar cartão'} description={'Deseja realmente deletar o cartão?'} />
            <Footer />
        </>
    );
}



export default Assinatura;