
import NavBar from '../../../Components/NavBar'
import NavBarMenu from '../../../Components/NavMenu/cartao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import Cookies from 'js-cookie'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalCreditCard from '../../../Components/Modal/CreditCard'

const Cartoes = () => {

    const { checkLogin, getCreditCard, deleteCreditCard, addCreditCard } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    const getCardsData = async () => {
        const response = await getCreditCard()

    }

    useEffect(() => {
        isSigned()
        if (states.dataCards.length === 0) {
            getCardsData()
        }
        return () => {
            setters.setDataCards([])
            setters.setCard({})
        }
    }, [])

    const openModal = (card) => {
        setters.setCard(card)
        setters.setModalConfirm(true)
    }

    const confirmModal = async () => {
        await deleteCreditCard()
        setters.setModalConfirm(false)
    }

    const addCreditCardOpenModal = () => {
        setters.setModalCreditCard(true)

    }

    const actionModal = async (data) => {
        await addCreditCard(data)
        setters.setModalCreditCard(false)
    }



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarMenu />
                            <div className="flex flex-col sm:flex-row w-full items-between justify-between">
                                <div className="flex sm:hidden items-center">
                                    <button
                                        className="text-blue-500 hover:text-blue-400 px-4 py-2 rounded"
                                        onClick={addCreditCardOpenModal}
                                    >
                                        <PlusCircleIcon className="h-10 w-10" />
                                    </button>
                                </div>
                                <div className="flex flex-col w-full items-between justify-between">
                                    <h1 className="text-[30px] font-semibold leading-6 text-gray-900">Cartões de Crédito</h1>
                                    <p className="mt-2 text-lg text-gray-700">
                                        Cartões de crédito disponíveis para você
                                    </p>
                                    <p className="mt-2 text-lg text-red-500">
                                        ATENÇÃO: Os dados do seu cartão de crédito não ficam salvos em nosso sistema. <br />
                                        O cartão fica armazenado no gateway de pagamento (VINDI), que é uma empresa especializada em transações financeiras. <br />  As informações exibidas vem diretamente do gateway de pagamento, não contendo nenhum registro nos servidores do SINPREV e nem no banco de dados do SINPREV.
                                    </p>
                                </div>

                                <div className="hidden sm:flex items-center">
                                    <button
                                        className="text-blue-500 hover:text-blue-400 px-4 py-2 rounded "
                                        onClick={addCreditCardOpenModal}
                                    >
                                        <PlusCircleIcon className="h-10 w-10" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="px-4 sm:px-6 lg:px-8 mt-4 border p-5">
                                {
                                    states.dataCards.length === 0 ? (
                                        <>
                                            <h2 className="text-base font-semibold leading-6 text-gray-900">Cartões Disponíveis</h2>
                                            <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4">
                                                <p>Nenhum cartão disponível no momento</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h2 className="text-base font-semibold leading-6 text-gray-900">Cartões Disponíveis</h2>
                                            <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4">
                                                {states.dataCards.map((card, index) => (
                                                    <div key={index} className="border p-4 rounded-md hover:shadow">
                                                        <p><strong>Bandeira:</strong> {card.brand}</p>
                                                        <p><strong>4 últimos números:</strong> {card.finalNumber}</p>
                                                        <p><strong>Nome:</strong> {card.name}</p>
                                                        <p><strong>Status:</strong> {
                                                            card.status == 'active' ? (
                                                                <span className="text-green-500">Ativo</span>
                                                            ) : card.status == 'deleted' ? (
                                                                <span className="text-red-500">Deletado</span>
                                                            ) : (
                                                                <span className="text-yellow-500">Inativo</span>
                                                            )
                                                        }</p>
                                                        <button
                                                            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                                            onClick={() => openModal(card)}
                                                        >
                                                            Deletar cartão
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <ModalCreditCard states={states} setters={setters} action={actionModal} />
            </div>
            <ModalConfirm action={confirmModal} states={states} setters={setters} title={'Deletar cartão'} description={'Deseja realmente deletar o cartão?'} />
            <Footer />
        </>
    );
}



export default Cartoes;