
import { Link } from 'react-router-dom'
const {jsonConfig} = require('../../Certs')

const notFoundPage = () => {
  return (
    <>
    {/* Efeito de degrade  */}
      <main className="flex bg-gradient-to-b from-primary-600 to-primary-700">
        <img
          src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full  object-top"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
          <img src={`${jsonConfig.urlReact}/logo.png`} alt="Workcation" className="mx-auto h-24 w-auto" />
          <p className="text-base text-[60px] font-semibold leading-8 text-white mt-8">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Página não encontrada</h1>
          <p className="mt-4 text-base text-white sm:mt-6">Desculpe, a página que você está procurando não existe.</p>
          <div className="mt-10 flex justify-center">
            <Link to="/" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-100 bg-primary-600 hover:bg-primary-700 ">
              <button className='focus:outline-none focus:ring-2 focus:ring-offset-2  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-[10px] p-8 m-4
              '>Voltar para o início</button>
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}

export default notFoundPage
