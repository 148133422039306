import { useState } from 'react';
import { jsonConfig } from '../../../Certs';

const CreditCardForm = ({formData, setFormData, setErrors, errors}) => {
   
    

    return (
        <div className="flex w-full flex-col sm:flex-row items-center justify-center  ">
            <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4 " >
                <h2 className="text-2xl font-semibold mb-6">Cadastro de Cartão de Crédito</h2>
                <div className="grid grid-cols-1 gap-4 mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardNumber">
                        Número do Cartão
                    </label>
                    <input
                        className="p-3 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                        type="text"
                        name="cardNumber"
                        id="cardNumber"
                        placeholder="Número do Cartão"
                        value={formData.cardNumber}
                        onBlur={e => {
                            if (e.target.value.length >= 16) {
                                setErrors(prevState => ({
                                    ...prevState,
                                    cardNumber: false
                                }));
                            } else {
                                setErrors(prevState => ({
                                    ...prevState,
                                    cardNumber: true
                                }));
                            }}
                        }
                        onChange={ e => {
                            const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                            if (onlyNumbers.length <= 19) {
                                setFormData(prevState => ({
                                    ...prevState,
                                    cardNumber: onlyNumbers
                                }));
                            }
                        }}
                    />
                    {
                        errors.cardNumber && <p className="text-red-500 text-xs mt-1">Número do cartão inválido</p>
                    }
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="mb-6 md:mb-0">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expMonth">
                            Mês de Expiração
                        </label>
                        <input
                            className="p-3 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                            type="text"
                            name="expMonth"
                            id="expMonth"
                            placeholder="MM"
                            value={formData.expMonth}
                            onChange={ e => {
                                const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                if (onlyNumbers.length <= 2) {
                                    setFormData(prevState => ({
                                        ...prevState,
                                        expMonth: onlyNumbers
                                    }));
                                }
                            }}
                            onBlur={e => {
                                if (e.target.value.length < 2) {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        expMonth: true
                                    }));
                                } else {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        expMonth: false
                                    }));
                            }}}
                        />
                        {
                            errors.expMonth && <p className="text-red-500 text-xs mt-1">Mês de expiração inválido</p>
                        }
                    </div>
                    <div className="mb-6 md:mb-0 ml-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expYear">
                            Ano de Expiração
                        </label>
                        <input
                            className="p-3 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                            type="text"
                            name="expYear"
                            id="expYear"
                            placeholder="AAAA"
                            value={formData.expYear}
                            onChange={ e => {
                                const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                if (onlyNumbers.length <= 4) {
                                    setFormData(prevState => ({
                                        ...prevState,
                                        expYear: onlyNumbers
                                    }));
                                }
                            }}
                            onBlur={e => {
                                if (e.target.value.length < 4) {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        expYear: true
                                    }));
                                } else {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        expYear: false
                                    }));
                                }}
                            }
                        />
                        {
                            errors.expYear && <p className="text-red-500 text-xs mt-1">Ano de expiração inválido</p>
                        }
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-6 mt-2">
                    <div className="mb-6 md:mb-0">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardHolder">
                            Nome do Titular
                        </label>
                        <input
                            className="p-3 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                            type="text"
                            name="cardHolder"
                            id="cardHolder"
                            placeholder="Nome do Titular"
                            value={formData.cardHolder}
                            onChange={e => {
                                const onlyLetters = e.target.value.replace(/[^a-zA-Z\s]/g, '')
                                if (onlyLetters.length <= 50) {
                                    setFormData(prevState => ({
                                        ...prevState,
                                        cardHolder: onlyLetters
                                    }));
                                }
                            }}
                            onBlur={e => {
                                if (e.target.value.length < 1) {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        cardHolder: true
                                    }));
                                } else {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        cardHolder: false
                                    }));
                                }
                            }}
                        />
                        {
                            errors.cardHolder && <p className="text-red-500 text-xs mt-1">Nome do titular inválido</p>
                        }
                    </div>
                    <div className="mb-6 md:mb-0 ml-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvv">
                            CVV
                        </label>
                        <input
                            className="p-3 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
                            type="text"
                            name="cvv"
                            id="cvv"
                            placeholder="CVV"
                            value={formData.cvv}
                            onChange={e => {
                                const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                if (onlyNumbers.length <= 4) {
                                    setFormData(prevState => ({
                                        ...prevState,
                                        cvv: onlyNumbers
                                    }));
                                }
                            }}
                            onBlur={e => {
                                if (e.target.value.length < 3) {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        cvv: true
                                    }));
                                } else {
                                    setErrors(prevState => ({
                                        ...prevState,
                                        cvv: false
                                    }));
                                }
                            }}
                        />
                        {
                            errors.cvv && <p className="text-red-500 text-xs mt-1">CVV inválido</p>
                        }
                    </div>
                </div>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6 sm:ml-12">
                <h3 className="text-xl font-semibold mb-4">Visualização do Cartão</h3>
                <div className="bg-gray-100 p-4 rounded-md">
                    <div className="flex justify-end">
                        <img
                            className="w-16 h-16"
                            src={`${jsonConfig.urlReact}/7339636.png`}
                            alt="Chip"
                        />
                    </div>
                    <div className="flex justify-between items-center mt-4">
                        <div>
                            <span className="text-lg font-semibold">{formData.cardNumber ? formData.cardNumber.padEnd(16, '●').match(/.{1,4}/g).join(' ') : '**** **** **** ****'}</span>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-4">
                        <div>
                            <span className="text-sm font-medium">Titular</span>
                            <span className="text-sm ml-2">{formData.cardHolder || 'NOME DO TITULAR'}</span>
                        </div>
                        <div>
                            <span className="text-sm font-medium">Validade</span>
                            <span className="text-sm ml-2">{`${formData.expMonth || 'MM'}/${formData.expYear || 'AAAA'}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditCardForm;
