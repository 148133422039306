import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CreditCardForm from './CreditCardForm' // Importando o componente do formulário de cartão de crédito
import moment from 'moment'

const ModalConfirm = ({ states, setters, title, description, action, fatura }) => {
    const [open, setOpen] = useState(true)
    const cancelButtonRef = useRef(null)


    const [formData, setFormData] = useState({
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cardHolder: '',
        cvv: '',
        plano_id: '',
        data: ''
    });

    const [errors, setErrors] = useState({
        cardNumber: false,
        expMonth: false,
        expYear: false,
        cardHolder: false,
        cvv: false,
        plano_id: false,
        data: false,
        dataMsg: ''
    });



    const submitForm = async (e) => {
        e.preventDefault()
        let error = false
        Object.keys(formData).map(key => {
            if (formData[key] === '') {
                error = true
                setErrors(prevState => ({
                    ...prevState,
                    [key]: true
                }))
                if (key === 'data') {
                    setErrors(prevState => ({
                        ...prevState,
                        dataMsg: 'Campo obrigatório'
                    }))
                }
            }
        })
        const haveErrosTrue = Object.keys(errors).filter(key => errors[key] === true)
        if (haveErrosTrue.length > 0) {
            return;
        }
        if (error) {
            return;
        }
        // const { number, verification_value, first_name, last_name, month, year } = req.body
        const namesCard = formData.cardHolder.split(' ')
        namesCard.shift()
        const last_name = namesCard.join(' ')

        const obj = {
            number: formData.cardNumber,
            verification_value: formData.cvv,
            first_name: formData.cardHolder.split(' ')[0],
            last_name: last_name,
            month: formData.expMonth,
            year: formData.expYear,
            plan_id: formData.plano_id,
            initial_date: formData.data
        }
        await action(obj)
    }


    const changeDate = (e) => {
        const selectedDate = e.target.value;
        const day = moment(selectedDate).date();

        if ([7,14,21,28].includes(day)) {
            setFormData(prevState => ({
                ...prevState,
                data: selectedDate
            }));
            setErrors(prevState => ({
                ...prevState,
                data: false,
                dataMsg: ''
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                data: ''
            }));
            setErrors(prevState => ({
                ...prevState,
                data: true,
                dataMsg: 'Data inválida. Selecione apenas os dias 7, 14, 21 ou 28.'
            }));
        }
    }



    useEffect(() => {
        if (states.modalCreditCard == false) {
            setFormData({
                cardNumber: '',
                expMonth: '',
                expYear: '',
                cardHolder: '',
                cvv: '',
                plano_id: '',
                data: ''
            })
            setErrors({
                cardNumber: false,
                expMonth: false,
                expYear: false,
                cardHolder: false,
                cvv: false,
                plano_id: false,
                data: false,
                dataMsg: ''
            })
        }
    }, [states.modalCreditCard])

    return (
        <Transition.Root show={states.insertSafePay} as={Fragment}>

            <Dialog as="div" className="fixed inset-0 z-5 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >

                        <div className="inline-block align-bottom bg-white rounded-lg overflow-y-auto text-left shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start w-full">
                                    <div className="mt-3 flex-col text-center sm:mt-0 sm:text-left flex w-full">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {description}
                                            </p>
                                        </div>
                                        <div className=' flex items-center flex-col justify-center mb-4'>
                                            <div className="flex items-center justify-center flex-col">
                                                <h1 className='text-md font-medium text-gray-900'>Olá, {states.Profile.name}</h1>
                                                <p className='text-md font-medium text-gray-900 t p-2 text-center mt-3'>
                                                    Para atender da melhor forma possível, o SINPREV efetivará a troca da plataforma de pagamentos (da PagSeguro para a Vindi) dos planos de associação.<br />
                                                    Precisamos que você insira novamente os dados do seu cartão de crédito, logo selecionar a data de vencimento e o plano desejado.</p>
                                            </div>

                                            <div className='flex items-center justify-center flex-col sm:flex-row mt-4 border p-5 w-full mb-8'>
                                                <div className="flex items-center justify-center flex-col">
                                                    <h1 className='text-md font-medium text-gray-900'>Data de vencimento</h1>
                                                    <input
                                                        type='date'
                                                        min={moment().add(1, 'days').format('YYYY-MM-DD')}
                                                        max={moment().add(1, 'months').format('YYYY-MM-DD')}
                                                        className='w-40 h-10 border border-gray-300 rounded-md p-2'
                                                        onChange={(e) => changeDate(e)}
                                                    />
                                                    {
                                                        errors.data && <p className='text-red-500 text-sm'>{errors.dataMsg}</p>
                                                    }
                                                </div>

                                                <div className="flex items-center justify-center flex-col mt-4 sm:mt-0 sm:ml-4 ">
                                                    <h1 className='text-md font-medium text-gray-900'>Plano</h1>
                                                    <select
                                                        className='w-40 h-10 border border-gray-300 rounded-md p-2'
                                                        onChange={(e) => setFormData(prevState => ({
                                                            ...prevState,
                                                            plano_id: e.target.value
                                                        }))}
                                                    >
                                                        <option value=''>Selecione</option>
                                                        <option value='cd654poiju-694a-4a02-ba38-10402fb29a2f'>Plano Mensal - R$ 32,90 - SendBox</option>
                                                        <option value='a499625c-694a-4a02-ba38-10402fb29a2f'>Plano Mensal - R$ 32,90</option>
                                                        <option value='755eb5d9-2bce-49af-bce9-b245a422e454'>Plano Trimestral - R$ 98,70</option>
                                                        <option value='412b27f4-0b1a-422c-934d-00a1eb4e06aa'>Plano Semestral - R$ 197,40</option>
                                                        <option value='4563f966-1c97-4480-ab92-c15ada2cb700'>Plano Anual - R$ 394,80</option>
                                                    </select>
                                                    {
                                                        errors.plano_id && <p className='text-red-500 text-sm'>Campo obrigatório</p>
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                        <CreditCardForm action={action} cancelButtonRef={cancelButtonRef} formData={formData} setFormData={setFormData} setErrors={setErrors} errors={errors} />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={submitForm}
                                >
                                    Atualizar cadastro
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalConfirm
