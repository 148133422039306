import NavBar from '../../../Components/NavBar'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import Footer from '../../../Components/Footer'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import moment from 'moment'
import NavMenu from '../../../Components/NavMenu/juridico'

import Faq from '../../../Components/FaqInformativo'

const Juridico = () => {
    const { checkLogin, getDocsOfficiaisInformativo } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)


    const isSigned = async () => {
        const response = await checkLogin()
        if (response === true) {

            setters.setSigned(true)
        } else {
            setters.setSigned(false)
        }
    }


    const load = async () => {
        await getDocsOfficiaisInformativo()

    }

    useEffect(() => {
        setters.setLimitSearchJuridico(false)
        setters.setOffsetJuridico(0)
        isSigned()
        if (states.dataJuridico.length === 0) {
            load()
        }

        return () => {
            setters.setDataJuridico([])
            setters.setLimitSearchJuridico(false)
            setters.setOffsetJuridico(0)
        }
    }, [])



    useEffect(() => {
        load()
    }, [states.offsetJuridico])

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <div className="flex flex-1 items-center justify-center">
                    <SpinnerOverlay />

                    <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                        <div className="p-4 border">
                            <NavMenu />
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-[28px] font-semibold leading-6 text-gray-900">Informativo Jurídico</h1>
                                <p className="mt-2 text-lg text-gray-700">
                                    Olá! Abaixo você irá encontrar os informativos do do SINPREV.
                                </p>
                            </div>
                        </div>
                        <div className="p-4 border">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <h1 className="text-[24px] text-base font-semibold leading-6 text-gray-900">Informativo jurídico</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    {
                                        states.dataDocsAgo.length > 0 ? (
                                            <>
                                                {
                                                    states.dataDocsAgo.map((doc, index) => {
                                                        return (
                                                            <>
                                                                <Faq key={index} faqs={doc} />
                                                                <hr className="my-8 p-2 border-t-4 border-blue-400" />
                                                            </>
                                                        )
                                                    }
                                                    )
                                                }

                                            </>
                                        ) : (
                                            <div className="flex flex-col items-center justify-center">
                                                <p className="text-lg font-semibold text-gray-900">Nenhum documento encontrado</p>
                                            </div>
                                        )
                                    }
                                </p>
                                {
                                    states.limitSearchJuridico === false && states.dataJuridico.length > 0 && <button onClick={() => setters.setOffsetJuridico(states.offsetJuridico + states.limitJuridico)} className="bg-blue-500 text-white p-2 rounded mt-2">Carregar mais</button>
                                }
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default Juridico