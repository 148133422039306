
import NavBar from '../../../Components/NavBar'
import NavBarMenu from '../../../Components/NavMenu/comunicacao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Faqs from '../../../Components/Faq'

const PerguntasFrequentes = () => {

    const { checkLogin, getFaqs } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    const getFaqsData = async () => {
        await getFaqs()

    }




    useEffect(() => {
        isSigned()
        if (states.faqs.length === 0) {
            getFaqsData()
        }
        return () => {
            setters.setFaqs([])
        }
    }, [])



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            {/* <NavBarMenu /> */}
                            <h1 className="text-[30px] text-base font-semibold leading-6 text-gray-900">Perguntas Frequentes</h1>
                        </div>
                        {
                            states.faqs.length > 0 ? (
                                <div className="px-4 sm:px-6 lg:px-8 p-8 mt-5 ">
                                    <div className="px-4 sm:px-6 lg:px-8 border rounded-[10px] shadow p-8">
                                        <Faqs faqs={states.faqs} />
                                    </div>
                                </div>
                            ) : (
                                /* Nao encontrado - menssage */
                                <div className="px-4 sm:px-6 lg:px-8 p-8 mt-5 ">
                                    <div className="px-4 sm:px-6 lg:px-8 border rounded-[10px] shadow p-8">
                                        <p className="text-center text-gray-500">Sem perguntas frequentes registradas no momento</p>
                                    </div>
                                </div>
                            )
                        }


                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}



export default PerguntasFrequentes;