import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CreditCardForm from './CreditCardForm' // Importando o componente do formulário de cartão de crédito

const ModalConfirm = ({ states, setters, title, description, action, fatura }) => {
    const [open, setOpen] = useState(true)
    const cancelButtonRef = useRef(null)

    const close = () => {
        setters.setModalCreditCard(false)
    }

    const [formData, setFormData] = useState({
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cardHolder: '',
        cvv: ''
    });

    const [errors, setErrors] = useState({
        cardNumber: false,
        expMonth: false,
        expYear: false,
        cardHolder: false,
        cvv: false
    });

    useEffect(() => {
        if (states.modalCreditCard == false) {
            setFormData({
                cardNumber: '',
                expMonth: '',
                expYear: '',
                cardHolder: '',
                cvv: ''
            })
            setErrors({
                cardNumber: false,
                expMonth: false,
                expYear: false,
                cardHolder: false,
                cvv: false
            })
        }
    }, [states.modalCreditCard])


    const submitForm = async (e) => {
        e.preventDefault()
        let error = false
        Object.keys(formData).map(key => {
            if(formData[key] === ''){
                error = true
                setErrors(prevState => ({
                    ...prevState,
                    [key]: true
                }))
            }
        })
        if(error){
            return;
        }
        // const { number, verification_value, first_name, last_name, month, year } = req.body
        const namesCard = formData.cardHolder.split(' ')
        namesCard.shift()
        const last_name = namesCard.join(' ')
        
        const obj ={
            number: formData.cardNumber,
            verification_value: formData.cvv,
            first_name: formData.cardHolder.split(' ')[0],
            last_name: last_name,
            month: formData.expMonth,
            year: formData.expYear
        }
        await action(obj)
    }

    return (
        <Transition.Root show={states.modalCreditCard} as={Fragment}>

            <Dialog as="div" className="fixed inset-0 z-5 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        
                        <div className="inline-block align-bottom bg-white rounded-lg overflow-y-auto text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {description}
                                            </p>
                                        </div>
                                        <CreditCardForm action={action} close={close} cancelButtonRef={cancelButtonRef} formData={formData} setFormData={setFormData} setErrors={setErrors} errors={errors} />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={submitForm}
                                >
                                    {
                                        fatura === true ? 'Pagar Fatura' : 'Cadastrar Cartão'
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={close}
                                    ref={cancelButtonRef}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalConfirm
