
import NavBar from '../../../Components/NavBar'
import NavBarMenu from '../../../Components/NavMenu/comunicacao'
import { toast } from 'react-toastify'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'
import New from '../../../Components/New'

const Noticias = () => {

    const { checkLogin, getNews } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()
    const id = useParams()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    useEffect(() => {
        isSigned()
        if (Object.keys(states.News).length === 0) {
            const news = states.dataNews.filter((item) => item.id === parseInt(id.id))
            if(news.length > 0){
                setters.setNews(news[0])
                return;
            }
            setters.setNews({})
            setters.setLimitSearchNews(false)
            setters.setOffsetNews(0)
            navigate('/comunicacao/noticias')
            toast.error('Selecione uma notícia para visualizar')
        }
        return () => {
            setters.setNews({})
            setters.setLimitSearchNews(false)
            setters.setOffsetNews(0)
        }
    }, [])

    const  downloadBase64File = (base64String, fileName)  => {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${base64String}`;
        link.download = fileName; // Define o nome do arquivo para download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    



    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-6">
                            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full' onClick={() => navigate('/noticias')}>
                                <ArrowLeftCircleIcon className='h-6 w-6 inline-block' />
                            </button>
                        </div>
                        {/* Construcao de noticia -> Vem o titulo, o texto formatado, a imagem caso tenha. Casa nao tenha apenas nao exibe. Alem de um link para baixar um arquivo caso tenha e caso nao tenha sem o botao, alem de outro 
                        botao para um link que possivelmente foi adicionado.  */}
                        <New data={states.News} download={downloadBase64File}/>




                    </div>

                </div>
                <Footer />
            </div>
        </>
    );
}



export default Noticias;