



import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusIcon } from '@heroicons/react/24/outline'


const Faq = ({ faqs }) => {
    return (
        <div className="mx-auto max-w-8xl divide-y divide-gray-900/10">
            <p className="mt-2 text-lg text-gray-700">
                Perguntas frequentes sobre o SINPREV
            </p>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.id} className="pt-6">
                        {({ open }) => (
                            <>
                                <dt>
                                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                                        <span className="ml-6 flex h-7 items-center">
                                            {open ? (
                                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <PlusIcon className="h-6 w-6" aria-hidden="true" />
                                            )}
                                        </span>
                                    </Disclosure.Button>
                                </dt>
                                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </dl>
        </div>
    )
}


export default Faq