



import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusIcon } from '@heroicons/react/24/outline'




const ViewPdf = async (base64Data) => {
    const binaryData = atob(base64Data);
    const len = binaryData.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([bytes.buffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');

};


const Faq = ({ faqs }) => {
    return (
        <Disclosure as="div" className="pt-6">
            {({ open }) => (
                <>

                    <dt>
                        <Disclosure.Button className="flex w-full sm:flex-row flex-col items-start justify-center sm:justify-between text-left text-gray-900">
                            <button onClick={() => ViewPdf(faqs.data)} className="">
                                <span className="mt-2 text-lg text-blue-600 hover:text-blue-400">Vizualizar {faqs.title}</span>
                            </button>
                        </Disclosure.Button>
                    </dt>
                </>

            )}
        </Disclosure>
    )
}



export default Faq