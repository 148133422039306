
import NavBar from '../../../Components/NavBar'
import NavBarMenu from '../../../Components/NavMenu/cartao'
import SpinnerOverlay from '../../../Components/SpinnerOverlay'
import Footer from '../../../Components/Footer'
import { AuthContextApi } from '../../../Context/api'
import { AuthContext } from '../../../Context'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import Cookies from 'js-cookie'
import ModalConfirm from '../../../Components/Modal/Confirm'
import ModalCreditCard from '../../../Components/Modal/CreditCard'
import moment from 'moment'
import { truncate } from '../../../utils'

const Faturas = () => {

    const { checkLogin, getInvoices, payedInvoice } = useContext(AuthContextApi)
    const { setters, states } = useContext(AuthContext)
    const navigate = useNavigate()

    async function isSigned() {
        const token = Cookies.get('token')
        if (token === null) {
            setters.setSigned(false)
        } else {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                Cookies.remove('token')
                setters.setSigned(false)
                navigate('/')
            }

        }
    }

    const getCardsData = async () => {
         await getInvoices()

    }

    useEffect(() => {
        isSigned()
        if (states.dataFaturas.length === 0) {
            getCardsData()
        }
        return () => {
            setters.setDataFaturas([])
            setters.setLimitSearchFaturas(false)
            setters.setOffsetFaturas(0)
            setters.setFatura({})
        }
    }, [])

    useEffect(() => {
        // //console.log(states.dataFaturas)
        //console.log(states.dataFaturas)
    }, [states.dataFaturas])

    const formatToBRL = (value) => {
        const realValue = value / 100
        return realValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    useEffect(() => {
        if (states.limitSearchFaturas) {
            return;
        }
        if (states.offsetFaturas > 0) {
            getCardsData()
        }
    }, [states.offsetFaturas])


    const openModalPayment = (data) => {
        setters.setModalCreditCard(true)
        setters.setFatura(data)
    }


    const actionModal = async (data) => {
        const objSend = {
            ...data,
            invoice_id: states.Fatura.id
        }
        setters.setModalCreditCard(false)
        await payedInvoice(objSend)

    }


    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <NavBar />
                <SpinnerOverlay />
                <div className='flex flex-1 flex-col md:pl-64 mb-4 mt-2'>
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <NavBarMenu />
                            <div className="flex flex-col sm:flex-row w-full items-between justify-between">

                                <div className="flex flex-col w-full items-between justify-between">
                                    <h1 className="text-[30px] font-semibold leading-6 text-gray-900">Faturas</h1>
                                    <p className="mt-2 text-lg text-gray-700">
                                        Faturas registradas no SINPREV
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="px-4 sm:px-6 lg:px-8 mt-4 border p-5">

                                {
                                    states.dataFaturas.length === 0 ? (
                                        <>
                                            <h2 className="text-base font-semibold leading-6 text-gray-900">Faturas Cadastradas</h2>
                                            <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4">
                                                <p>Nenhuma fatura cadastrada no momento</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h2 className="text-base font-semibold leading-6 text-gray-900">Faturas Cadastradas</h2>
                                            <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-4 p-5">
                                                {states.dataFaturas.map((card, index) => {
                                                    return (
                                                        <div key={index} className="bg-white shadow hover:shadow-lg rounded-lg p-6 rounded-[10px]">
                                                            <div className="flex justify-between">
                                                                <h2 className="text-lg font-semibold leading-6 text-gray-900">{
                                                                    card.idSignature !== '0' ? 'Fatura de assinatura' : truncate(card.description, 40)
                                                                }</h2>
                                                                <h2 className="text-lg font-semibold leading-6 text-gray-900">{
                                                                    card.status === 'canceled' ? 'Cancelada' : card.status === 'paid' ? 'Paga' : 'Pendente'
                                                                }</h2>
                                                            </div>
                                                            <div className="flex justify-between mt-4">
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">Valor</h2>
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">{formatToBRL(card.price)}</h2>
                                                            </div>
                                                            <div className="flex justify-between mt-4">
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">Data de criação</h2>
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">{moment(card.created_at).format('DD/MM/YYYY')}</h2>
                                                            </div>
                                                            <div className="flex justify-between mt-4">
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">Data de vencimento</h2>
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">{moment(card.experies_at).format('DD/MM/YYYY')}</h2>
                                                            </div>
                                                            <div className="flex justify-between mt-4">
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900">Pagamento: </h2>
                                                                <h2 className="text-sm font-semibold leading-6 text-gray-900 ml-2">{
                                                                    (card.payment === '0' || card.payment === '1') ? 'Método não registrado' : card.payment === 'credit_card' ? 'Cartão de crédito' : 'Boleto bancário'
                                                                }</h2>
                                                            </div>
                                                            {/** Botao para pagamento e para copiar o link */}
                                                            <div className="flex justify-between mt-4">
                                                                {
                                                                    card.path !== '/' &&
                                                                    <button onClick={() => window.open(card.path, '_blank')} className="bg-blue-600 text-white p-2 rounded-md hover:shadow-md">Abrir Fatura</button>

                                                                }
                                                                {
                                                                    card.path !== '/' && card.status !== 'canceled' && card.status !== 'paid' &&
                                                                    <button onClick={() => openModalPayment(card)} className="bg-blue-600 text-white p-2 rounded-md hover:shadow-md">Pagar</button>
                                                                }
                                                            </div>


                                                        </div>
                                                    )
                                                })}
                                                {
                                                    states.limitSearchFaturas === false && states.haveMoreFaturas ? (
                                                        <div className="bg-white shadow hover:shadow-lg rounded-lg p-6 rounded-[10px] flex items-center justify-center">
                                                            <button onClick={() => setters.setOffsetFaturas(states.offsetFaturas + states.limitFaturas)} className="flex items-center justify-center">
                                                                <PlusCircleIcon className="h-10 w-10 text-blue-600" />
                                                            </button>
                                                        </div>
                                                    ) : <></>
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <ModalCreditCard states={states} setters={setters} action={actionModal} fatura={true} title='Adição de cartão' description='Adicione um cartão para pagamento de faturas' />
            </div>
            {/* <ModalConfirm action={confirmModal} states={states} setters={setters} title={'Deletar cartão'} description={'Deseja realmente deletar o cartão?'} /> */}
            <Footer />
        </>
    );
}



export default Faturas;