import { truncate } from '../../utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const files = [
    {
        title: 'Beautiful Sunset',
        description: 'A stunning view of the sunset over the ocean.',
        size: '3.9 MB',
        source: 'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
    },
    // Adicione mais objetos ao array se necessário
];

const News = ({ news, states, setters, more }) => {
    const [isLoading, setIsLoading] = useState(true); // Estado para controlar o status de carregamento da imagem
    const navigate = useNavigate();

    const handleImageLoad = () => {
        setIsLoading(false); // Define isLoading como false quando a imagem termina de carregar
    };


    const getNew = async (item) => {
        setters.setNews(item)
        navigate(`/noticia/${item.id}`)
    }

    return (
        <div className='flex flex-col'>
            <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {news.map((file, index) => (
                    <li key={index} className="relative">
                        {isLoading && <div className="absolute inset-0 flex items-center justify-center bg-gray-100 opacity-75"><p className="text-gray-600">Loading...</p></div>}
                        {/* Aqui tem que colocar o link para ir para a noticia -> se coloca dentro do span*/}
                        <span className="block group aspect-h-7 aspect-w-10 overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100" onClick={() => getNew(file)} >
                            <div className="gradient absolute inset-0 opacity-0 group-hover:opacity-100"></div>
                            {
                                file.pathImage === null ? (
                                    <div className="relative">
                                        <img
                                            src='https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80'
                                            alt="ImageNews"
                                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105"
                                            style={{ aspectRatio: '8/9', width: '100%', height: '100%' }}// Defina a mesma proporção de aspecto da imagem em Base64
                                        />
                                        <div className="absolute inset-0 bg-black opacity-50"></div>
                                    </div>
                                ) : (
                                    <div className="relative">
                                        <img
                                            src={`data:image/webp;base64, ${file.pathImage}`}
                                            alt="ImageNews"
                                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105"
                                            onLoad={handleImageLoad}
                                            style={{ aspectRatio: '8/9', width: '100%', height: '100%' }}// Defina a mesma proporção de aspecto da imagem em Base64
                                        />
                                        <div className="absolute inset-0 bg-black opacity-50"></div>
                                    </div>
                                )
                            }
                            <div className="absolute inset-0 flex flex-col justify-between items-center text-white pointer-events-none p-4">
                                <div>
                                    <h2 className="text-sm font-semibold mb-2">{file.title}</h2>
                                    <p className="text-xs">{truncate(file.description, 50)}</p>
                                </div>
                                <div className="text-xs">Clique para visualizar</div>
                            </div>
                        </span>
                    </li>
                ))}
            </ul>
            {
                states.limitSearchNews === false && states.haveMoreNews === true &&
                <span className="text-center text-blue-500 font-semibold cursor-pointer mt-8" onClick={more}>
                    Carregar mais
                </span>
            }
        </div>
    );
}

export default News;
