
import { AuthContext } from "../../Context"
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContextApi } from "../../Context/api"
import {  useContext, useEffect } from "react"
import Footer from "../../Components/Footer"
import { jsonConfig } from "../../Certs"
import StepOneLogin from "../../Components/Step1Login"
import StepTwoLogin from "../../Components/Step2Login"
import ForgotPassword from "../../Components/ForgotPassword"
import Cookies from 'js-cookie'


const Login = () => {
    //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAyOWM1M2UwLWI2OGEtNDMwYS05YTEwLTYzMmFmZjRjMmVkMCIsImNwZiI6IjU1MDIxMzM2NjQ5IiwiYXNzb2NpYXRlX2lkIjoiZjkzNjE5OTktOWI5YS00ZThlLTljZWYtYjQ5NTBjYjc0MmQ2IiwiaWF0IjoxNzA4ODI5MDE4fQ.br-eoT0HMVWyE1LfbyEs3MT4TnKHZQkmMQ362SxUbtQ
    const { states, setters } = useContext(AuthContext)
    const { loginStep1, loginStep2, forgotPassword, resetPassword } = useContext(AuthContextApi)
    const navigate = useNavigate()
    const { token } = useParams()

    const actionStep1 = async () => {
        const response = await loginStep1()
        if (response) {
            setters.setStep1(false)
            setters.setStep2(true)
            setters.setConfirmPasswordInput('')
            setters.setPasswordInput('')
            setters.setErrorPassword(false)
            setters.setErrorConfirmPassword(false)
            setters.setForgotPassword(false)

        }
    }

    const actionStep2 = async () => {
        const response = await loginStep2()
        if (response) {
            navigate('/dashboard')
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            setters.setErrorCpf(false)
            setters.setErrorPassword(false)
            setters.setErrorConfirmPassword(false)
            setters.setForgotPassword(false)

        }

    }

    const actionForgotPassword = async () => {
        const response = await resetPassword()
        if (response) {
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            setters.setErrorCpf(false)
            setters.setErrorPassword(false)
            setters.setErrorConfirmPassword(false)
            setters.setForgotPassword(false)
            setters.setCpfInput('')
            setters.setPasswordInput('')
            setters.setAssociate_id('')
            setters.setName('')
            setters.setTokenForgotPassword('')
            navigate('/')
        }
    
    }


    useEffect(() => {
        setters.setStep1(true)
        setters.setStep2(false)
        setters.setIsComplete(false)
        setters.setErrorCpf(false)
        setters.setErrorPassword(false)
        setters.setErrorConfirmPassword(false)
        setters.setCpfInput('')
        setters.setPasswordInput('')
        setters.setAssociate_id('')
        setters.setName('')
        if (token !== undefined && token !== '' && token !== null) {
            setters.setForgotPassword(true)
            setters.setTokenForgotPassword(token)
        }else{
            setters.setForgotPassword(false)
            setters.setTokenForgotPassword('')
        }
        Cookies.remove('token')
        return () => {
            setters.setStep1(true)
            setters.setStep2(false)
            setters.setIsComplete(false)
            setters.setErrorCpf(false)
            setters.setErrorPassword(false)
            setters.setErrorConfirmPassword(false)
            setters.setCpfInput('')
            setters.setPasswordInput('')
            setters.setAssociate_id('')
            setters.setName('')
            setters.setForgotPassword(true)
            setters.setTokenForgotPassword('')
        }
    }, [])

    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96 items-center jutify-center">
                            <div className="flex flex-col justify-center items-center">
                                <img
                                    className="h-[5em] w-auto"
                                    src={`${jsonConfig.urlReact}/logo-sinprev-resp.png`}
                                    alt="Sinprev logo"
                                />
                                <h2 className="mt-6 text-3xl text-center font-bold tracking-tight text-gray-900">Seja bem-vindo a área do associado</h2>
                            </div>
                            {
                                states.forgotPassword === true &&
                                <ForgotPassword token={token} action={actionForgotPassword} states={states} setters={setters} />
                            }
                            {
                                states.forgotPassword === false &&
                                <>
                                    <StepOneLogin action={actionStep1} stap={states.step1} name={states.name} complete={states.isComplete} states={states} setters={setters} />
                                    {
                                        states.step2 === true &&
                                        <StepTwoLogin action={actionStep2} stap={states.step2} complete={states.isComplete} forgot={forgotPassword} states={states} setters={setters} />
                                    }
                                </>
                            }


                            <div className="mt-8 flex items-center justify-center">

                                <Footer />
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                            alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login