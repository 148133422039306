import { truncate } from '../../utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const files = [
    {
        title: 'Beautiful Sunset',
        description: 'A stunning view of the sunset over the ocean.',
        size: '3.9 MB',
        source: 'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
    },
    // Adicione mais objetos ao array se necessário
];

const News = ({ data, states, setters, download }) => {

    const formatText = (text) => {
        text = text?.replace(/^"(.*)"$/, '$1');

        // Dividir o texto em parágrafos com base nas quebras de linha
        const paragraphs = text?.split('\n');

        // Criar uma seção para cada parágrafo
        const sections = paragraphs?.map((paragraph, index) => <p key={index} style={{ marginBottom: '1em' }}>{paragraph}</p>);

        return sections;
    }

    // //console.log(data)

    return (
        <div className='flex flex-col border p-7 mt-5 rounded-[10px]'>
            <div className="flex flex-col md:flex-row">
            <div className="md:hidden md:w-1/2 md:pl-4 flex">
                    <div className="flex flex-col h-full justify-between">
                        <div>
                            {/* Aqui você pode adicionar a imagem */}
                            {
                                data.pathImage === null ? (
                                    <div className="relative">
                                        <img
                                            src='https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80'
                                            alt="ImageNews"
                                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105 rounded-[10px] border-2 border-gray-200"
                                            style={{ aspectRatio: '8/9', width: '100%', height: '100%' }}// Defina a mesma proporção de aspecto da imagem em Base64
                                        />
                                    </div>
                                ) : (
                                    <div className="relative">
                                        <img
                                            src={`data:image/webp;base64, ${data.pathImage}`}
                                            alt="ImageNews"
                                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105 rounded-[10px] border-2 border-gray-200"
                                            style={{ aspectRatio: '8/9', width: '80%', height: '100%' }}// Defina a mesma proporção de aspecto da imagem em Base64
                                        />
                                    </div>
                                )
                            }
                            {/* Aqui você pode adicionar o link */}
                            <div className="relative mt-4">

                                {
                                    data.link !== null ? (
                                        <a href={data.link} className="text-md text-blue-600 hover:text-blue-500 mt-8">Clique para visualizar o link da notícia</a>
                                    ) : null
                                }
                            </div>
                            <div className="relative">

                                {
                                    data.fileFile !== null ? (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-24 rounded mt-2"
                                        onClick={() => download(data.pathFile, data.fileFile)}
                                        >Baixar arquivo</button>
                                    ) : null
                                }
                            </div>

                        </div>
                        {/* Aqui você pode adicionar o botão */}
                    </div>
                </div>
                <div className="md:w-1/2">
                    <h1 className="text-2xl mb-5 font-semibold text-gray-900 mt-5">{data.title}</h1>
                    <p className="text-sm text-gray-700 mb-5">Data de publicação: {moment(data.date).format('DD/MM/YYYY HH:mm:ss')}</p>
                    <span className='mt-8'>{formatText(data.description)}</span>
                </div>
                <div className="md:flex md:w-1/2 md:pl-4 hidden">
                    <div className="flex flex-col h-full justify-between">
                        <div>
                            {/* Aqui você pode adicionar a imagem */}
                            {
                                data.pathImage === null ? (
                                    <div className="relative">
                                        <img
                                            src='https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80'
                                            alt="ImageNews"
                                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105 rounded-[10px] border-2 border-gray-200"
                                            style={{ aspectRatio: '8/9', width: '100%', height: '100%' }}// Defina a mesma proporção de aspecto da imagem em Base64
                                        />
                                    </div>
                                ) : (
                                    <div className="relative">
                                        <img
                                            src={`data:image/webp;base64, ${data.pathImage}`}
                                            alt="ImageNews"
                                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105 rounded-[10px] border-2 border-gray-200"
                                            style={{ aspectRatio: '8/9', width: '80%', height: '100%' }}// Defina a mesma proporção de aspecto da imagem em Base64
                                        />
                                    </div>
                                )
                            }
                            {/* Aqui você pode adicionar o link */}
                            <div className="relative mt-4">

                                {
                                    data.link !== "null" ? (
                                        <a href={data.link} className="text-md text-blue-600 hover:text-blue-500 mt-8">Clique para visualizar o link da notícia</a>
                                    ) : null
                                }
                            </div>
                            <div className="relative">

                                {
                                    data.fileFile !== null ? (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-24 rounded mt-2"
                                        onClick={() => download(data.pathFile, data.fileFile)}
                                        >Baixar arquivo</button>
                                    ) : null
                                }
                            </div>

                        </div>
                        {/* Aqui você pode adicionar o botão */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;
